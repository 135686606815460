import React, { Component } from 'react';
import PropTypes from 'prop-types';
import reactStringReplace from 'react-string-replace';
import dayjs from 'dayjs';

import RideInfoTag from './RideInfoTag';
import { Link } from '@flixbus/honeycomb-react';
import { IconCheckmarkStrongSolid } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../system/Translate';

class NotificationInfo extends Component {
    static contextType = TranslateContext;

    constructor(props, context) {
        super(props, context);
        this.descriptionFormatters = {
            BIG_DELAY: this.DELAY,
            SMALL_DELAY: this.DELAY,
            MEDIUM_DELAY: this.DELAY,
            DELAY_REDUCTION: this.DELAY,
            BUSCHANGE_QUESTIONABLE: this.BUSCHANGE_QUESTIONABLE,
            BUS_CHANGE_CANCELLED: this.BUS_CHANGE_CANCELLED,
            SUSPECTED_BUS_CHANGE: this.SUSPECTED_BUS_CHANGE,
            TRACK_CHANGE: this.TRACK_CHANGE,
            HALT_START: this.HALT_START,
            HALT_END: this.HALT_END,
            CANCELLATION_RIDE: this.CANCELLATION_RIDE,
            UETDS_ERROR: this.UETDS_ERROR,
            PAX2BUS_DISTRIBUTION_ERROR: this.PAX2BUS_DISTRIBUTION_ERROR,
            PAX2BUS_UNDISTRIBUTED_ORDERS: this.PAX2BUS_UNDISTRIBUTED_ORDERS,
            WHEELCHAIR_RIDE: this.WHEELCHAIR_RIDE,
            RIDE_ARCHIVED: this.RIDE_ARCHIVED,
            GHOST_ORDERS: this.GHOST_ORDERS,
            DELAY_GOODIES: this.DELAY_GOODIES,
            NO_CHECKIN_ON_FIRST_STOP: this.NO_CHECKIN_ON_FIRST_STOP,
        };
    }

    // TODO: add translation for description when PAX number will be available in DTO
    DELAY_GOODIES() {
        return '';
    }

    NO_CHECKIN_ON_FIRST_STOP () {
        return '';
    }

    CANCELLATION_RIDE(descriptionTranslation) {
        return descriptionTranslation;
    }
    GHOST_ORDERS(descriptionTranslation) {
        const { notification } = this.props;
        const { metaInformation } = notification;
        const meta =
            (metaInformation && (metaInformation[0] || metaInformation)) || {};
        const ordersNumber =
            typeof meta.affectedOrdersCount === 'undefined' ? 0 : meta.affectedOrdersCount;
        return descriptionTranslation.replace(
            '%amount%',
            ordersNumber
        )
    }
    UETDS_ERROR(descriptionTranslation) {
        const { notification } = this.props;
        const { metaInformation } = notification;
        const meta =
            (metaInformation && (metaInformation[0] || metaInformation)) || {};
        const resultMessage =
            typeof meta.resultMessage === 'undefined' ? '' : meta.resultMessage;

        const description = descriptionTranslation.replace(
            '%result_message%',
            resultMessage
        );

        return description;
    }

    PAX2BUS_DISTRIBUTION_ERROR(descriptionTranslation) {
        const { notification } = this.props;
        const { metaInformation } = notification;
        const meta =
            (metaInformation && (metaInformation[0] || metaInformation)) || {};
        const resultMessage =
            typeof meta.message === 'undefined' ? '' : meta.message;

        const description = descriptionTranslation.replace(
            '%message%',
            resultMessage
        );

        return description;
    }

    PAX2BUS_UNDISTRIBUTED_ORDERS(descriptionTranslation) {
        const { notification } = this.props;
        const { metaInformation } = notification;
        const meta =
            (metaInformation && (metaInformation[0] || metaInformation)) || {};
        const ordersNumber =
            typeof meta.ordersNumber === 'undefined' ? 0 : meta.ordersNumber;

        const description = descriptionTranslation.replace(
            '%ordersNumber%',
            ordersNumber
        );

        return description;
    }

    DELAY() {
        const { notification } = this.props;
        const mapDelayToColor = {
            'BIG_DELAY': 'b31414',
            'MEDIUM_DELAY': 'c42d00',
            'SMALL_DELAY': '136b00'
        }

        return notification.delayString ? (
            <RideInfoTag
                type="colored"
                lineColor={mapDelayToColor[notification?.type] || 'f7f7f7'}
                small
            >
                {notification.delayString}
            </RideInfoTag>
        ) : null;
    }

    BUS_CHANGE_CANCELLED(descriptionTranslation) {
        const { notification } = this.props;
        const { metaInformation } = notification;
        const { translate } = this.context;
        const rideViewUrl = this.props.rideViewUrl;
        let totalPax = 0;
        let estimatedArrival;
        const connections = metaInformation.map((r) => {
            totalPax += r.totalPassengers;
            estimatedArrival = dayjs
                .unix(r.estimatedArrival)
                .tz(r.timezone)
                .format('HH:mm');

            return (
                <div>
                    {r.totalPassengers} from{' '}
                    <RideInfoTag lineColor={r.lineColor} type="colored">
                        <a
                            href={rideViewUrl.replace('{rideId}', r.rideUuid)}
                            target="_blank"
                            title={
                                translate('line') +
                                ' ' +
                                r.lineCode +
                                ' TN' +
                                r.tripNumber
                            }
                            className="ride-info-tag__line-indicator"
                            rel="noreferrer"
                        >
                            <IconCheckmarkStrongSolid
                                className="ride-info-tag__icon"
                                style={{ fill: `#${r.lineColor}` }}
                            />
                            {r.lineCode} TN{r.tripNumber}
                        </a>
                    </RideInfoTag>{' '}
                    PDT:{' '}
                    {dayjs
                        .unix(r.plannedDeparture)
                        .tz(r.timezone)
                        .format('HH:mm')}
                </div>
            );
        });

        const description = descriptionTranslation
            .replace('%passenger_count%', totalPax);

        return (
            <div>
                {description}
                {connections}
            </div>
        );
    }

    BUSCHANGE_QUESTIONABLE(descriptionTranslation) {
        const { notification } = this.props;
        const { metaInformation } = notification;
        const { translate } = this.context;
        const rideViewUrl = this.props.rideViewUrl;
        let totalPax = 0;
        let estimatedArrival;
        const connections = metaInformation.map((r) => {
            totalPax += r.totalPassengers;
            estimatedArrival = dayjs
                .unix(r.estimatedArrival)
                .tz(r.timezone)
                .format('HH:mm');

            return (
                <div key={r.rideUuid}>
                    {r.totalPassengers} to{' '}
                    <RideInfoTag lineColor={r.lineColor} type="colored">
                        <a
                            href={rideViewUrl.replace('{rideId}', r.rideUuid)}
                            target="_blank"
                            title={
                                translate('line') +
                                ' ' +
                                r.lineCode +
                                ' TN' +
                                r.tripNumber
                            }
                            className="ride-info-tag__line-indicator"
                            rel="noreferrer"
                        >
                            <IconCheckmarkStrongSolid
                                className="ride-info-tag__icon"
                                style={{ fill: `#${r.lineColor}` }}
                            />
                            {r.lineCode} TN{r.tripNumber}
                        </a>
                    </RideInfoTag>{' '}
                    PDT:{' '}
                    {dayjs
                        .unix(r.plannedDeparture)
                        .tz(r.timezone)
                        .format('HH:mm')}
                </div>
            );
        });

        const description = descriptionTranslation
            .replace('%passenger_count%', totalPax)
            .replace('%station%', notification.segment.segmentToName)
            .replace('%estimated_arrival%', estimatedArrival);

        return (
            <div>
                {description}
                {connections}
            </div>
        );
    }

    SUSPECTED_BUS_CHANGE(descriptionTranslation) {
        const { notification } = this.props;
        const { metaInformation } = notification;
        const meta =
            (metaInformation && (metaInformation[0] || metaInformation)) || {};
        const busViewUrl = this.props.busViewUrl;
        const { translate } = this.context;

        // TODO: fix this undefined
        let description = '';
        if (typeof meta.expectedBusPlates !== 'undefined') {
            description = descriptionTranslation.replace(
                '%expected_plates%',
                meta.expectedBusPlates.join(` ${translate('or')} `)
            );
        }

        if (meta.hasOwnProperty('pairedBusId')) {
            return reactStringReplace(
                description,
                '%actual_plate%',
                (match, i) => (
                    <a
                        href={busViewUrl.replace('{busId}', meta.pairedBusId)}
                        key={i}
                    >
                        {meta.pairedBusPlate}
                    </a>
                )
            );
        }

        return description.replace('%actual_plate%', meta.pairedBusPlate);
    }

    TRACK_CHANGE(descriptionTranslation) {
        const { notification } = this.props;
        const { metaInformation } = notification;
        const meta =
            (metaInformation && (metaInformation[0] || metaInformation)) || {};
        const { trainNumber, plannedTrack, changedTrack, stopLegacyId } = meta;

        const description = descriptionTranslation
            .replace('%train_number%', trainNumber)
            .replace('%planned_track%', plannedTrack)
            .replace('%changed_track%', changedTrack)
            .replace('%station%', this.props.stationNameById(stopLegacyId));

        return description;
    }

    HALT_START(descriptionTranslation) {
        return descriptionTranslation;
    }

    HALT_END(descriptionTranslation) {
        return descriptionTranslation;
    }

    RIDE_ARCHIVED(descriptionTranslation) {
        return descriptionTranslation;
    }

    WHEELCHAIR_RIDE(descriptionTranslation) {
        const orderViewUrl = this.props.orderViewUrl;
        const ordersLinks = this.props.notification.metaInformation.map(
            (orderId) => (
                <Link
                    extraClasses="result-item__order-link"
                    key={orderId}
                    href={orderViewUrl.replace('{orderId}', orderId)}
                    target="blank"
                    rel="noreferrer"
                >
                    {orderId}
                </Link>
            )
        );
        return (
            <>
                {descriptionTranslation} {ordersLinks}
            </>
        );
    }

    // Formats description if exists appropriate formatting function
    formatDescription(descriptionTransl) {
        if (
            typeof this.descriptionFormatters[this.props.notification.type] ===
            'function'
        ) {
            return this.descriptionFormatters[
                this.props.notification.type
            ].call(this, descriptionTransl);
        }

        return descriptionTransl;
    }

    render() {
        const { notification } = this.props;
        const { translate } = this.context;
        const typeLowerCase = notification.type.toLowerCase();
        const typeTranslation = translate(`${typeLowerCase}_type`);
        const descriptionTranslation = translate(
            `${typeLowerCase}_description`
        );

        if (
            typeof typeTranslation !== 'string' ||
            typeof descriptionTranslation !== 'string'
        ) {
            console.warn(
                `There are no translations for notification.type=${notification.type}`
            );
            return null;
        }

        return (
            <div className="result-item__notif-type">
                <div className="result-item__notif-type-name">
                    <RideInfoTag small>{typeTranslation}</RideInfoTag>
                </div>
                <div className="result-item__notif-type-desc">
                    {this.formatDescription(descriptionTranslation)}
                </div>
            </div>
        );
    }
}

NotificationInfo.propTypes = {
    stationNameById: PropTypes.func.isRequired,
    notification: PropTypes.object.isRequired,
    rideViewUrl: PropTypes.string.isRequired,
    busViewUrl: PropTypes.string.isRequired,
};

export default NotificationInfo;
